.EditableItem {
    min-height: 50px;
    width: 100%;
}

.EditableItem-Text {
    height: 100%;
    width: auto;
    min-width: 250px !important;
    border: none;
    padding: 10px;
    background-color: white;    
    font-size: 10pt;
}

.EditableItem-IconButton {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 5px !important;
    margin-top: 8px !important;
    font-size: 18px;
}
.EditableItem-IconButton:hover {
    padding-top: 0px !important;
}

.EditableItem-Button {
    height: 25px;
    width: auto;
    background-color: white;
    border-right: 1px solid whitesmoke;
    border-bottom: 1px solid whitesmoke;
    color: grey;
}

.EditableItem-Icon {
    font-size: 18px !important;
    vertical-align: middle;
}
.EditableItem-Link-Icon {
    font-size: 18px !important;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 5px;
} 

.EditableItem-Button_add:hover {
    color: black;
}
.EditableItem-Button_save:hover {
    color: black;
}
.EditableItem-Button_remove:hover {
    color: orangered;
}

.EditableItem-Icon_remove {
    color: red;
}